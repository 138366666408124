import React from 'react'

import PropTypes from 'prop-types'

import './races.css'

const Races = (props) => {
  return (
    <div className={`races-container ${props.rootClassName} `}>
      <div className="races-ostatnie-starty">
        <div className="races-container1">
          <div className="races-container2">
            <div className="races-container3">
              <h1 className="races-heading">
                <span className="Section-Heading races-text">{props.name}</span>
                <br className=""></br>
              </h1>
            </div>
            <div className="races-container4">
              {Object.keys(props.results).map((eventName) => {
                return <div className="races-weight-container">
                  <h3 className="list-title">
                    <span className="">{eventName}</span>
                    <br className=""></br>
                  </h3>
                  <span className="races-text05 list-value">
                    <span className="">{props.results[eventName]}</span>
                    <br className=""></br>
                  </span>
                </div>
                })}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Races.defaultProps = {
  results: {},
  rootClassName: '',
}

Races.propTypes = {
  results: PropTypes.object,
  rootClassName: PropTypes.string,
}

export default Races
