import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import { useParams } from 'react-router-dom';

import Info from '../components/info'
import Races from '../components/races'
import './home.css'

const Home = (props) => {
  let { racer } = useParams();
  let [ racerData, setRacerData ] = useState();

  useEffect(() => {
    if (racer) {
      fetch(`/data/racers.json`).then((res) => {
        res.json().then((data) => {
          if (data[racer]) {
            setRacerData(data[racer]);
          } else {
            window.location.replace('http://majbike.com');
          }

        });
      });
    }

  }, [racer]);

  return (
    <div className="home-container">
      <Helmet>
        <title>MajBike Racer Page</title>
        <meta property="og:title" content="MajBike Racer Page" />
      </Helmet>
      <header data-role="Header" className="home-header">
        <a
          href="http://majbike.com"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link"
        >
          <img
            alt="logo"
            src="/img/logo-1400w.png"
            className="home-image"
          />
        </a>
      </header>
      <Info
        rootClassName="info-root-class-name"
        profileImg={racerData ? `/img/racers/${racer}.png` : ''}
        name={racerData ? racerData.name : ''}
        age={racerData ? racerData.age : ''}
        since={racerData ? racerData.since : ''}
        weight={racerData ? `${racerData.weight} kg` : ''}
        ftp={racerData ? `${racerData.ftp} W` : ''}
        wkg={racerData ? (racerData.ftp / racerData.weight) : ''}
        twitter={racerData ? racerData.twitter : ''}
        instagram={racerData ? racerData.instagram : ''}
        facebook={racerData ? racerData.facebook : ''}
        strava={racerData ? racerData.strava : ''}
      ></Info>
      <Races
        results={racerData ? racerData.achievements : {}}
        name="Sukcesy sportowe"
        rootClassName="races-root-class-name"
      ></Races>
      <Races
        rootClassName="races-root-class-name1"
        name="Ostatnie wyścigi"
        results={racerData ? racerData.recent : {}}
      ></Races>
      <div className="home-footer">
        <div className="home-footer1">
          <div className="home-copyright-container">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM506 390q-80 0-80 116v12q0 116 80 116 30 0 50-17t20-43h76q0 50-44 88-42 36-102 36-80 0-122-48t-42-132v-12q0-82 40-128 48-54 124-54 66 0 104 38 42 42 42 98h-76q0-14-6-26-10-20-14-24-20-20-50-20z"></path>
            </svg>
            <span className="Anchor">Copyright, {new Date().getFullYear()}, MajBike Team</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
